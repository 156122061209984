import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/global/layout';
import Container from '../components/global/container';
import Header from '../components/global/header';
import Cover from '../static/showreel-cover-headnorth.png';
import Showreel from '../static/HeadNorth-showreel.mp4';
import PlayButton from '../static/play--button.svg';

const ShowreelPage = (props) => {
    const { data, errors } = props;

    const showreelVideo = useRef();
    const playButton = useRef();

    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlay = e => {
        if(!isPlaying) {
        setIsPlaying(true);
          showreelVideo.current.play();
        } else {
        setIsPlaying(false);
          showreelVideo.current.pause();
        }
    }

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  
  return (
    <>
        <Header headerStyle="clear" />
        <Layout>
        <Container>
            <div className='site-wide'>
                <div className='aviva--page-banner-image showreel'>
                    <div className='video--wrapper'>
                        {isPlaying ? null :
                            <div className='video--overlay' onClick={handlePlay}>
                                <img src={PlayButton} ref={playButton} />
                            </div>
                        }
                        <video
                            ref={showreelVideo}
                            className="hero__media"
                            controls="false"
                            poster={Cover}
                        >
                            <source src={Showreel} type="video/mp4" />
                        </video>
                    </div>
                </div>
            </div>
        </Container>
        </Layout>
    </>
  )
};

ShowreelPage.propTypes = {
    data: PropTypes.object,
    errors: PropTypes.object
  };

export default ShowreelPage;
